import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import BannerImage from '../../assets/static/banner/banner_tainan.jpg'
import EnvironmentImg1 from '../../assets/static/clinic/tainan_environment1.png'
import EnvironmentImg2 from '../../assets/static/clinic/tainan_environment2.png'
import EnvironmentImg3 from '../../assets/static/clinic/tainan_environment3.png'
import EnvironmentImg4 from '../../assets/static/clinic/tainan_environment4.png'
import EnvironmentImg5 from '../../assets/static/clinic/tainan_environment5.png'
import EnvironmentImg6 from '../../assets/static/clinic/tainan_environment6.png'
import consultImage from '../../assets/static/consult/consult1.jpg'
import Button from '../../components/Button'
import { getClinicInfo } from '../../components/Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../components/Grid'
import ClinicLayout from '../../components/Layout/ClinicLayout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import { Hsinchu, Taichung, Tainan, TainanSecondary, TainanYellow } from '../../constants/newColor'
import { Body, H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { Location } from '../../types/types'
import CaseSection from './components/CaseSection'
import Consult from './components/Consult'
import DoctorSection from './components/Doctor'
import GoogleMap from './components/GoogleMap'
import LogoInfo from './components/LogoInfo'
import OtherClinic from './components/OtherClinic'
import TimetableSection from './components/Timetable'
import { tainanTimetableData } from './components/Timetable/staticData'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContentTitle = styled.h2`
${H2}
color: ${TainanSecondary};
margin:0 auto ${space.l}px auto;
display: flex;
flex-direction: column;
align-items: flex-start;
&:after {
  content: '';
  display: block;
  width: 4rem;
  height: 4px;
  margin: ${space.s}px 0;
  background-color: ${TainanYellow};
}
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  @media (min-width: ${viewport.tablet}px) {
    height: 187px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 81px;
  }
`

const Info = styled.div`
  ${Body}
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MoreCase = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
`

const ConsultWrapper = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${viewport.desktop}px) {
    height: 406px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 294px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 200px;
  }
`

const ClinicTainan = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    },
    {
      label: formatMessage({ id: 'clinic.tainan' }),
      path: '/clinic/tainan'
    }
  ]

  return (
    <ClinicLayout imageUrl={BannerImage} pageBannerInfo={formatMessage({ id: 'banner.tainan' })} routes={routes} {...props}>
      <Seo
        title='【台南牙齒矯正推薦】蒔光美學牙醫集團：隱形矯正、矯正專科、陶瓷貼片推薦'
        metaDescription='台南東區蒔光美學牙醫診所，位於長榮女中、成功大學旁，擁有台大、北醫多位矯正專科醫師，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙等牙科治療，『進入蒔光、為您帶來好時光』是蒔光服務的堅持。'
      />
      <LogoInfo location={Location.Tainan} />
      <Content>
        <GridLayout templateColumns={['100%']} templateAreas={[`'imgSection1' 'info' 'imgSection2'`, `'info' 'imgSection1' 'imgSection2'`]} rowGap={['24px', '120px']}>
          <GridItem area='imgSection1'>
            <GridLayout templateColumns={['2fr 1fr 1fr']}>
              <Img src={EnvironmentImg1} />
              <Img src={EnvironmentImg2} />
              <Img src={EnvironmentImg3} />
            </GridLayout>
          </GridItem>
          <GridItem area='info'>
            <ContentTitle>轉譯完美微笑基因</ContentTitle>
            <Info>
              <p>
                位於台南市東區、長榮女中及成大中心的「蒔光美學牙醫」，由來自台北東區的SUNLIGHT美學牙醫團隊，一起提供給您最優質的 牙齒矯正、陶瓷貼片、兒童牙科服務。
                融合日式服務款待精神與台灣專業牙科治療，『對待患者如同家人朋友』是我們的服務宗旨，讓蒔光團隊一起帶給您最溫暖的看診時光。
              </p>
            </Info>
          </GridItem>
          <GridItem area='imgSection2'>
            <GridLayout templateColumns={['1fr 1fr 2fr']}>
              <Img src={EnvironmentImg4} />
              <Img src={EnvironmentImg5} />
              <Img src={EnvironmentImg6} />
            </GridLayout>
          </GridItem>
        </GridLayout>
      </Content>
      <Content>
        <ContentTitle>專業團隊</ContentTitle>
        <DoctorSection page={Location.Tainan} />
      </Content>
      <Content>
        <ContentTitle>矯正心得分享</ContentTitle>
        <CaseSection location={[Location.Tainan]} />
        <MoreCase href={`/case?location=${Location.Tainan}`}>
          <Button label='MORE &#9658;' type='secondary' />
        </MoreCase>
      </Content>
      <Content>
        <ConsultWrapper>
          <Consult color={Tainan} imageUrl={consultImage} />
        </ConsultWrapper>
      </Content>
      <Content>
        <ContentTitle>門診資訊</ContentTitle>
        <TimetableSection
          primaryColor={Tainan}
          secondaryColor={TainanSecondary}
          oddColor={Taichung}
          evenColor={Hsinchu}
          data={tainanTimetableData}
          tel={getClinicInfo({ location: Location.Tainan, value: 'tel' })}
          line={getClinicInfo({ location: Location.Tainan, value: 'socialMedia_line' })}
        />
      </Content>
      <Content id='location'>
        <GoogleMap lat={22.99183} lng={120.22133} title='sunlight_tainan' add={getClinicInfo({ location: Location.Tainan, value: 'address' })} />
      </Content>
      <Content>
        <ContentTitle>其他診所</ContentTitle>
        <OtherClinic />
      </Content>
    </ClinicLayout>
  )
}

export default ClinicTainan
